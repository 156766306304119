
import React from 'react';

const VectorIcon = () => {
    return (
        <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99675 10L0.330078 2.33333L2.66341 0L12.6634 10L2.66341 20L0.330078 17.6667L7.99675 10Z" fill="#D9D9D9" />
        </svg>
    )
}

export default VectorIcon