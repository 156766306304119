import React from "react";
import { graphql } from 'gatsby'
import Survey from "../components/SurveyPage/Survey";
import LayoutHeadFoot from "../components/Shared/Layouts/LayoutHeadFoot";
import SEO from "../components/seo";

const SurveyPage = ({ data }) => {
    const surveyData = data?.strapiSurvey;

    return (
        <>
        <SEO seo={surveyData.metadata}/>
        <LayoutHeadFoot>
            <Survey data={surveyData} />
        </LayoutHeadFoot>
        </>
    )
}

export const query = graphql`
query SurveyQuery {
    strapiSurvey {
        metadata {
            metaTitle
            metaDescription
            shareImage {
                localFile {
                    publicURL
                }
            }
        }
        banner {
            localFile {
                publicURL
            }
        }
        buttonLink
        buttonText
        bottomSection
        text1
        text2
        text3
        title
    }
}`

export default SurveyPage