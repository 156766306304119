import React from "react";

import VectorIcon from "../icons/Vector"

const Survey = ({ data }) => {
    return (
        <>
            <div
                className="font-sans pt-20 md:pt-28"
                style={{
                    background: 'transparent linear-gradient(0deg, #ffffff 0%, #f5f8fe 78%, #f1f5fe 100%) 0% 0% no-repeat padding-box',
                }}
            >
                <div className="container px-4 flex flex-col md:flex-row justify-between items-center">
                    <h2 className="w-full font-bold m-0 text-center pb-4 md:w-2/5 md:pr-6 md:pb-0 md:text-3xl md:text-left md:ml-28">{data.title}</h2>
                    <img src={data.banner.localFile.publicURL} alt="" className="w-full md:w-2/5" />
                </div>

                <div className="container w-full flex gap-0 px-4 mt-8 flex-wrap justify-around items-center md:justify-center md:mt-10 md:flex-row">
                    <div
                        className="surveyCard rounded-xl border p-3 flex flex-col items-center mb-5 md:bg-white"
                    >
                        <p
                            className="text-4xl md:text-5xl"
                            style={{ color: "rgb(72, 98, 155)" }}
                        >
                            1
                        </p>
                        <hr
                            className="my-2 w-full"
                            style={{ color: "rgb(233, 233, 233)" }}
                        />
                        <p className="text-base m-0 w-full font-normal text-center md:text-lg">
                            {data.text1}
                        </p>
                    </div>

                    <div className="hidden rotate-90 md:block md:rotate-0 md:mx-14">
                        <VectorIcon />
                    </div>

                    <div
                        className="surveyCard rounded-xl border p-3 flex flex-col items-center mb-5 md:bg-white"
                    >
                        <p
                            className="text-4xl md:text-5xl"
                            style={{ color: "rgb(72, 98, 155)" }}
                        >
                            2
                        </p>
                        <hr
                            className="my-2 w-full"
                            style={{ color: "rgb(233, 233, 233)" }}
                        />
                        <p className="text-base m-0 w-full font-normal text-center md:text-lg">
                            {data.text2}
                        </p>
                    </div>

                    <div className="hidden rotate-90 md:block md:rotate-0 md:mx-14">
                        <VectorIcon />
                    </div>

                    <div
                        className="surveyCard rounded-xl border p-3 flex flex-col items-center mb-5 md:bg-white"
                    >
                        <p
                            className="text-4xl md:text-5xl"
                            style={{ color: "rgb(72, 98, 155)" }}
                        >
                            3
                        </p>
                        <hr
                            className="my-2 w-full"
                            style={{ color: "rgb(233, 233, 233)" }}
                        />
                        <p className="text-base m-0 w-full font-normal text-center md:text-lg">
                            {data.text3}
                        </p>
                    </div>
                </div>

                <div className="container flex flex-col items-center my-6 px-4">
                    <a href={data.buttonLink} target="_blank" className="flex justify-center py-3 px-16 bg-blue text-white rounded-lg text-xl md:w-72 md:text-2xl md:py-2">{data.buttonText}</a>
                    <div dangerouslySetInnerHTML={{ __html: data.bottomSection }}></div>
                </div>
            </div>
        </>
    )
}

export default Survey
